import {IMarketplaceOfferCategory} from "~/types/marketplaceTypes";

export const useMarketplaceFiltrationStore = defineStore("marketplace-filtration", () => {
  const options = ref<IMarketplaceOfferCategory[]>([]);
  const search = ref(``)

  const reset = () => { options.value = []; search.value = `` }

  const totalCount = computed(() =>
    options.value.length +
    (search.value.trim().length > 0 ? 1 : 0)
  )

  return {
    options,
    search,
    reset,
    totalCount,
  };
});
