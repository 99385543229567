<template>
  <i18n-t
    keypath="default"
    tag="h1"
    class="display-3xl md:display-4xl text-pretty mb-6 md:mb-0"
  >
    <template #forward>
      <span class="text-blue-700">{{ t("forward") }}</span>
    </template>
  </i18n-t>
</template>

<script lang="ts" setup>
const { t } = useI18n({
  useScope: "local",
  messages: {
    cs: {
      default: "StartupMarket - služby, {forward}",
      forward: "které vás posunou vpřed",
    },
    en: {
      default: "StartupMarket - services that'll {forward}",
      forward: "help you level up",
    },
  },
});
</script>
